document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName('BODY')[0]
    const toggleMenu = document.getElementById('toggleMenu')
    const openMenu = document.getElementById('openMenu')
    const closeMenu = document.getElementById('closeMenu')
    function menu(element, add) {
        toggleMenu.addEventListener('click', () => {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')
    menu(openMenu, 'hide-icon')
    menu(closeMenu, 'show-icon')
})

/* Wood board control*/
document.addEventListener('DOMContentLoaded', () => {
    const arrowWrapper = document.querySelector('.arrow-wrapper')
    const woodBoard = document.querySelector('.wood-board')

    if (arrowWrapper) {
        arrowWrapper.addEventListener('click', () => {
            woodBoard.classList.toggle('board-hide')
        })
    }
})

/**
 * Control for gallery
 */
const body = document.getElementsByTagName('body')[0],
    srcImg = []

/**
 * Create html for view gallery
 *  @param {String} imgUrl - image src for open gallery
 */
function createHtml(imgUrl) {
    const html = `
    <div class='gallery-view'>
        <img class='gallery-img' src='${imgUrl}' alt='open image'>
        <div class='gallery-close'></div>
        <div class='gallery-previus'></div>
        <div class='gallery-next'></div>
    </div>
    `
    body.insertAdjacentHTML('afterbegin', html)
}

/**
 * Select parent (view gallery) for remove
 * @param {HTMLElement} element - event on this HTMLElement remove view gallery
 */
function removeParent(element) {
    element.addEventListener('click', () => {
        element.parentElement.remove()
    })
}

/**
 * Remove view gallery
 */
function removeGalleryView() {
    removeParent(document.querySelector('.gallery-close'))
    removeParent(document.querySelector('.gallery-img'))
}

/**
 * Set new image in view gallery
 * @param {HTMLElement} element - image HMTlElemnt in view gallery
 * @param {Number} url - item from array srcImg
 */
function setImg(element, url) {
    element.setAttribute('src', url)
}

/**
 * Previus and next image controll
 */
function controllGallery() {
    const galleryView = document.querySelector('.gallery-view'),
        galleryImg = document.querySelector('.gallery-img')

    galleryView.addEventListener('click', event => {
        const clickedElement = event.target.getAttribute('class'),
            imgIndex = srcImg.findIndex(
                src => src === galleryImg.getAttribute('src')
            )

        if (clickedElement === 'gallery-previus') {
            imgIndex < 1
                ? setImg(galleryImg, srcImg[srcImg.length - 1])
                : setImg(galleryImg, srcImg[imgIndex - 1])
        } else if (clickedElement === 'gallery-next') {
            imgIndex < srcImg.length - 1
                ? setImg(galleryImg, srcImg[imgIndex + 1])
                : setImg(galleryImg, srcImg[0])
        }
    })
}

document.addEventListener('DOMContentLoaded', () => {
    /**
     * Get all url images from gallery to array srcImg
     */
    const gallery = document.querySelector('.gallery'),
        allImg = document.querySelectorAll('.gallery img')

    allImg.forEach(img => {
        srcImg.push(img.getAttribute('src'))
    })

    /**
     * Open view gallery
     */
    if (gallery) {
        gallery.addEventListener('click', event => {
            if (event.target.classList.contains('img-fluid')) {
                createHtml(event.target.getAttribute('src'))
            }
            removeGalleryView()
            controllGallery()
        })
    }
})
